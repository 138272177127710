// Import favicons
import faviconAlternate from './img/favicon.png';
import favicon from './img/favicon.svg';

// Import fonts for webpack
import Swiss721BoldExtendedWoff from './fonts/Swiss721/Swiss721BT-BoldExtended.woff';
import Swiss721BoldExtendedWoff2 from './fonts/Swiss721/Swiss721BT-BoldExtended.woff2';
import Swiss721BTCondensedWoff from './fonts/Swiss721/Swiss721BT-Condensed.woff';
import Swiss721BTCondensedWoff2 from './fonts/Swiss721/Swiss721BT-Condensed.woff2';
import Swiss721Woff from './fonts/Swiss721/Swiss721BT-Regular.woff';
import Swiss721Woff2 from './fonts/Swiss721/Swiss721BT-Regular.woff2';
import Swiss721MediumWoff from './fonts/Swiss721/Swiss721BT-Medium.woff';
import Swiss721MediumWoff2 from './fonts/Swiss721/Swiss721BT-Medium.woff2';
import OpenDisplay from './fonts/OpenDisplay/Open-24-Display-St.ttf.woff';

// Dependencies
import "core-js/modules/es.promise";
import barba from '@barba/core';

// Components
import ScrollHandler from './components/ScrollHandler';
import VideoEmbed from './components/VideoEmbed';
import InViewport from './components/InViewport';
// import { StrokesOverlay } from './components/StrokesOverlay';

// Returns the next <name> event of `target`.
function nextEvent(target, name) {
  return new Promise(resolve => {
    const callback = () => {
      target.removeEventListener('transitionend', callback);
      resolve();
    }

    target.addEventListener(name, callback, false);
  });
}

const nextTick = () => new Promise(resolve => window.requestAnimationFrame(resolve));

//
// Application
//
const WCK = {
  // Options / globals
  body: null,
  header: null,
  navTrigger: null,
  navOpen: false,
  searchOpen: false,
  navTrigger: null,
  navBackdrop: null,
  searchTrigger: null,
  searchInput: null,
  searchClose: null,

  init() {
    this.body = document.body;
    this.header = document.getElementById('TheHeader');
    this.scrollHandler = new ScrollHandler(window);
    this.viewportObserver = new InViewport();
    this.navTrigger = document.getElementById('NavTrigger');
    this.navBackdrop = document.getElementById('NavBackdrop');
    this.searchTrigger = document.getElementById('SearchTrigger');
    this.searchInput = document.getElementById('SearchInput');
    this.searchClose = document.getElementById('SearchClose');

    // Init navigation
    this.navTriggerClickListener = this.toggleNavigation.bind(this);
    this.navTrigger.addEventListener('click', this.navTriggerClickListener);
    this.navBackdrop.addEventListener('click', this.navTriggerClickListener);

    // Init search
    this.searchTriggerClickListener = this.toggleSearch.bind(this);
    this.searchTrigger.addEventListener('click', this.searchTriggerClickListener);
    this.searchClose.addEventListener('click', this.searchTriggerClickListener);

    // Init strokes overlay
    // this.strokesOverlay = new StrokesOverlay(document.getElementById('StrokesOverlay'));

    this.scrollHandler.listen('pass:hero', evt => this.body.classList.toggle('has-scrolled-past-hero', evt.detail.isPast), false);

    // Barba page transitions
    // barba.init({
    //   transitions: [{
    //     name: 'default',
    //     beforeLeave: async ({current}) => {
    //       current.container.classList.add('before-leaving');

    //       return nextTick();
    //     },

    //     leave: async ({ current }) => {
    //       await nextTick();

    //       // Close nav if open
    //       if(this.navOpen) {
    //         this.toggleNavigation();
    //       }

    //       this.body.classList.remove('is-scrolling-down');
    //       current.container.classList.remove('before-leaving');
    //       current.container.classList.add('is-leaving');
    //       this.header.classList.add('hide');

    //       return nextEvent(current.container, 'transitionend');
    //     },

    //     afterLeave: async ({ current }) => {
    //       current.container.classList.remove('is-leaving');
    //       current.container.classList.add('after-leave');

    //       return this.strokesOverlay.animateIn();
    //     },

    //     beforeEnter: ({ next }) => {
    //       this.scrollHandler.scrollTo(0);

    //       // Init js on next page
    //       this.initPage(next.container);

    //       next.container.classList.add('before-entering');

    //       // Use namespace to inject dynamic classe
    //       this.body.className = next.namespace;

    //       return this.strokesOverlay.animateOut();
    //     },
    //     enter: ({ next }) => {
    //       next.container.classList.add('is-entering');
    //       this.header.classList.remove('hide');
    //       next.container.classList.remove('before-entering');

    //       return nextEvent(next.container, 'transitionend');
    //     },
    //     afterEnter: ({ next }) => {
    //       next.container.classList.remove('is-entering');
    //     }
    //   }]
    // });

    this.initPage(this.body);
  },

  initPage(root) {
    this.initObservers(root);
    this.initVideoEmbeds(root);
    this.initCountdown(root);
    this.initAccordions(root);
  },

  initObservers(root) {
    [...root.querySelectorAll('[data-in-viewport]')].forEach(el => this.viewportObserver.observe(el));
  },

  initVideoEmbeds(root) {
    [...root.querySelectorAll('.VideoEmbed')].forEach(el => {
      new VideoEmbed(el);
    });
  },

  initCountdown(root) {
    const countdownEl = root.querySelector('#Countdown');
    if(countdownEl) {
      import('./components/Countdown').then(module => {
        new module.default(countdownEl);
      });
    }
  },

  initAccordions(root) {
    [...root.querySelectorAll('[data-accordion-header]')].forEach(accordionHeader => {
      let target = accordionHeader.nextElementSibling;
      accordionHeader.onclick = (e) => {
        e.preventDefault();
        let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
        accordionHeader.setAttribute('aria-expanded', !expanded);
        target.setAttribute('aria-hidden', expanded);
      }
    });
  },

  toggleNavigation() {
    this.navOpen = !this.navOpen;
    this.body.classList.toggle('nav-open');
    if(this.navOpen) {
      this.navTrigger.setAttribute('aria-expanded', 'true');
      this.navTrigger.setAttribute('aria-label', this.navTrigger.dataset.closeLabel);
    } else {
      this.navTrigger.setAttribute('aria-expanded', 'false');
      this.navTrigger.setAttribute('aria-label', this.navTrigger.dataset.openLabel);
    }
  },

  toggleSearch() {
    this.searchOpen = !this.searchOpen;
    this.body.classList.toggle('search-open');
    this.searchTrigger.setAttribute('aria-expanded', this.searchOpen ? 'true' : 'false');
    if(this.searchOpen) {
      this.searchInput.focus();
    }
  },

  initOnLoad() {
    // console.log('WCK started!');
  },

  onResize() {
    // console.log('on resize');
  }
};

document.addEventListener('DOMContentLoaded', () => WCK.init());
window.addEventListener('load', () => WCK.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
